import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const IS_PROD = process.env.NODE_ENV === "production";
const DEFAULT_LOCAL_BASE_URL_BR = "http://localhost:60000";
const DEFAULT_LOCAL_BASE_URL_LATAM = "http://localhost:60000";
const DEFAULT_LOCAL_API_BASE_URL = "https://api.localnube.com";
const DEFAULT_LOCAL_TIENDANUBE_OAUTH_BASE_URL_BR =
  "https://www.localnube.com.br";
const DEFAULT_LOCAL_TIENDANUBE_OAUTH_BASE_URL_LATAM =
  "https://www.localnube.com";

export const env = createEnv({
  client: {
    NEXT_PUBLIC_BASE_URL_BR: IS_PROD
      ? z.string().url()
      : z.string().url().default(DEFAULT_LOCAL_BASE_URL_BR),
    NEXT_PUBLIC_BASE_URL_LATAM: IS_PROD
      ? z.string().url()
      : z.string().url().default(DEFAULT_LOCAL_BASE_URL_LATAM),
    NEXT_PUBLIC_HOTJAR_SITE_ID: z.preprocess(
      (val) => (val ? Number(val) : undefined),
      z.number().optional(),
    ),
    NEXT_PUBLIC_HOTJAR_VERSION: z.preprocess(
      (val) => (val ? Number(val) : undefined),
      z.number().optional(),
    ),
    NEXT_PUBLIC_APPCUES_ID: z.string().trim().min(1).optional(),
    NEXT_PUBLIC_AMPLITUDE_API_KEY: z.string().trim().min(1).optional(),
    NEXT_PUBLIC_SATISMETER_WRITE_KEY: z.string().trim().min(1).optional(),
  },
  server: {
    NEXTAUTH_SECRET: z.string().trim().min(1),
    NEXO_SECRET: z.string().trim().min(1),
    TIENDANUBE_API_BASE_URL: IS_PROD
      ? z.string().url()
      : z.string().url().default(DEFAULT_LOCAL_API_BASE_URL),
    TIENDANUBE_OAUTH_CLIENT_ID: z.string().min(1),
    TIENDANUBE_OAUTH_CLIENT_SECRET: z.string().min(1),
    TIENDANUBE_OAUTH_BASE_URL_BR: IS_PROD
      ? z.string().url()
      : z.string().url().default(DEFAULT_LOCAL_TIENDANUBE_OAUTH_BASE_URL_BR),
    TIENDANUBE_OAUTH_BASE_URL_LATAM: IS_PROD
      ? z.string().url()
      : z.string().url().default(DEFAULT_LOCAL_TIENDANUBE_OAUTH_BASE_URL_LATAM),
    MONOLITH_API_BASE_URL: IS_PROD
      ? z.string().url()
      : z.string().url().default(DEFAULT_LOCAL_API_BASE_URL),
    MONOLITH_ACCESS_TOKEN: z.string(),
    MAINTENANCE_MODE: z.coerce.boolean().optional(),
    BILLING_API_BASE_URL: z.string().url(),
    BILLING_API_USER: z.string(),
    BILLING_API_PASSWORD: z.string(),
  },
  shared: {
    NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
  },
  experimental__runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_BASE_URL_BR: process.env.NEXT_PUBLIC_BASE_URL_BR,
    NEXT_PUBLIC_BASE_URL_LATAM: process.env.NEXT_PUBLIC_BASE_URL_LATAM,
    NEXT_PUBLIC_HOTJAR_SITE_ID: process.env.NEXT_PUBLIC_HOTJAR_SITE_ID,
    NEXT_PUBLIC_HOTJAR_VERSION: process.env.NEXT_PUBLIC_HOTJAR_VERSION,
    NEXT_PUBLIC_APPCUES_ID: process.env.NEXT_PUBLIC_APPCUES_ID,
    NEXT_PUBLIC_AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
    NEXT_PUBLIC_SATISMETER_WRITE_KEY:
      process.env.NEXT_PUBLIC_SATISMETER_WRITE_KEY,
  },
  skipValidation: !!process.env.CI || !!process.env.SKIP_ENV_VALIDATION,
});
