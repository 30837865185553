import type { AppRouterInputs, AppRouterOutputs } from "@offline/app-trpc";

export { type SupportedCountryCode } from "@offline/constants/store";

export type Product =
  AppRouterOutputs["products"]["getProducts"]["items"][number];

export type ProductVariant = Product["variants"][number];

export type PostOrderOutput = AppRouterOutputs["orders"]["create"];

export type Order = AppRouterOutputs["orders"]["getOrder"];

export type PaymentMethod = Exclude<
  AppRouterInputs["orders"]["create"]["payment"]["method"],
  undefined
>;

export type PaymentMethodSettings =
  AppRouterOutputs["payments"]["getPaymentMethodSettings"]["settings"];

export type StoreInfo = AppRouterOutputs["store"]["getStoreInfo"];

export type Currency = StoreInfo["mainCurrency"];

export type PlanSubscriptionSummary =
  AppRouterOutputs["planSubscriptions"]["getSummary"];

export type LatestPayments =
  AppRouterOutputs["planSubscriptions"]["getLatestPayments"];

export type PlanOptions =
  AppRouterOutputs["planSubscriptions"]["getPlanOptions"];

export type Plan = PlanOptions["optionsByPeriod"][string][number]["plan"];

export type PlanPrice = PlanOptions["optionsByPeriod"][string][number]["price"];

export type PlanWithPrices = Plan & { planPrices: PlanPrice[] };

export type RegisterClosedPeriodItem =
  AppRouterOutputs["registers"]["getClosedPeriods"]["items"][0];

export enum PlanPeriod {
  MONTHLY = 30,
  QUARTERLY = 90,
  YEARLY = 360,
}

export type InfiniteListProps<T> = {
  items: T[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  isError: boolean;
  isLoadingError: boolean;
  isPending: boolean;
};

export type Locale = "es" | "pt" | "en" | "es_mx";
export type I18nField = Record<Locale, string>;

export type Location = AppRouterOutputs["store"]["getLocations"][number];

export type AdminLink = AppRouterOutputs["store"]["getAdminLinks"][number];
export type AdminLinkI18n =
  AppRouterOutputs["store"]["getAdminLinks"][number]["i18n"][number];

export type Customer =
  AppRouterOutputs["customers"]["getCustomers"]["items"][number];

export type ShippingOptions = AppRouterOutputs["orders"]["getShippingOptions"];
export type ShippingType = keyof ShippingOptions;

export type StoreUser = AppRouterOutputs["store"]["getStoreUsers"];
