"use client";

import { useEffect, useState } from "react";
import {
  CashIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  CreditCardIcon,
  InvoiceIcon,
  LocationIcon,
  LogOutIcon,
  QuestionCircleIcon,
} from "@nimbus-ds/icons";
import { signOut } from "next-auth/react";
import { useLocale, useTranslations } from "next-intl";

import {
  ConfirmationModal,
  Divider,
  MenuButton,
  Popover,
  Tag,
} from "~/components";
import { clearLocalStorageAndTrackingInfo } from "~/helpers";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { getLocaleFieldValue } from "~/helpers/i18n";
import { useUISmallScreenDrawerVisible, useUIStore } from "~/hooks";
import useIsSmallScreen from "~/hooks/useIsSmallScreen";
import useSelectedLocation from "~/hooks/useSelectedLocation";
import { useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import ShortcutListModalWidget from "~/widgets/ShortcutListModalWidget";
import SelectLocationModalWidget from "../SelectLocationModalWidget";
import UserNameInitials from "./UserNameInitials";

export default function AppMenu() {
  const t = useTranslations("widgets.app-menu");
  const router = useRouter();
  const locale = useLocale();
  const storeInfo = useStoreInfo();
  const isSmallScreen = useIsSmallScreen();

  const { name, hasMulticd, country, adminLanguage, userConfig, email } =
    storeInfo || {};
  const storeName = getLocaleFieldValue(name, locale);
  const countryLocale = `${adminLanguage}_${country}`;
  const firstName = userConfig?.firstName ?? undefined;
  const lastName = userConfig?.lastName ?? undefined;

  const smallScreenDrawerVisible = useUISmallScreenDrawerVisible();
  const { hideSmallScreenDrawer } = useUIStore();

  const { selectedOriginLocation, selectedFulfillmentLocation } =
    useSelectedLocation();

  const selectedOriginLocationName = selectedOriginLocation
    ? getLocaleFieldValue(selectedOriginLocation.name, countryLocale)
    : undefined;
  const selectedLocationOrStoreName = selectedOriginLocationName ?? storeName;

  const [menuOpen, setMenuOpen] = useState(false);
  const [shortcutListOpen, setShortcutListOpen] = useState(false);
  const [signOutConfirmationModalOpen, setSignOutConfirmationModalOpen] =
    useState(false);

  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);

  useEffect(() => {
    if (menuOpen || smallScreenDrawerVisible) {
      router.prefetch("/account");
      router.prefetch("/account/plans");
    }
  }, [smallScreenDrawerVisible, menuOpen, router]);

  const handleVisibilityChange = (visible: boolean) => {
    setMenuOpen(visible);

    if (visible) {
      trackAmplitudeEvent("pdv_headerbar_profile_click");
    }
  };

  const handleMyAccountClick = () => {
    router.push("/account");
    hideSmallScreenDrawer();
    trackAmplitudeEvent("pdv_profile_menu_payments_click");
  };

  const handlePlansClick = () => {
    router.push("/account/plans");
    hideSmallScreenDrawer();
    trackAmplitudeEvent("pdv_profile_menu_plans_click");
  };

  const handleChangeLocationClick = () => {
    setSelectLocationModalOpen(true);
    setMenuOpen(false);
    trackAmplitudeEvent("pdv_profile_menu_location_click");
    hideSmallScreenDrawer();
  };

  const handlePaymentMethodsClick = () => {
    router.push("/settings/payment-methods");
    setMenuOpen(false);
    trackAmplitudeEvent("pdv_settings_navbar_payments_click");
    hideSmallScreenDrawer();
  };

  const handleSignOutClick = () => {
    setMenuOpen(false);
    setSignOutConfirmationModalOpen(true);
    trackAmplitudeEvent("pdv_profile_menu_logout_click");
    hideSmallScreenDrawer();
  };

  const handleSignOutConfirmation = () => {
    clearLocalStorageAndTrackingInfo();
    signOut();
  };

  const handleHelpIconClick = () => {
    setShortcutListOpen(true);
    trackAmplitudeEvent("pdv_headerbar_help_click");
  };

  return (
    <>
      {isSmallScreen && smallScreenDrawerVisible && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col gap-4 bg-white p-4">
          <button
            onClick={hideSmallScreenDrawer}
            className="absolute right-4 top-4"
          >
            <CloseIcon size="medium" className="cursor-pointer" />
          </button>

          <h1 className="text-2xl font-bold">{storeName}</h1>

          <div className="flex items-center gap-2">
            <UserNameInitials firstName={firstName} lastName={lastName} />

            <div className="flex flex-col">
              <div className=" font-medium">
                {firstName} {lastName}
              </div>
              <div className="text-sm">{email}</div>
            </div>
          </div>

          {selectedOriginLocationName && (
            <Tag appearance="success">
              <div className="flex items-center gap-1">
                <LocationIcon />
                {selectedOriginLocationName}
              </div>
            </Tag>
          )}

          <div className="flex flex-col gap-2 pt-2">
            <Divider />
            <MenuButton
              label={t("menuItems.paymentsAndSubscriptions")}
              startIcon={() => <CashIcon size={18} />}
              onClick={handleMyAccountClick}
              align="right"
            />

            <Divider />
            <MenuButton
              label={t("menuItems.plans")}
              startIcon={() => <InvoiceIcon size={18} />}
              onClick={handlePlansClick}
            />

            {hasMulticd && (
              <>
                <Divider />
                <MenuButton
                  label={t("menuItems.changeLocation")}
                  startIcon={() => <LocationIcon size={18} />}
                  onClick={handleChangeLocationClick}
                />
              </>
            )}

            <Divider />
            <MenuButton
              label={t("menuItems.paymentMethods")}
              startIcon={() => <CreditCardIcon size={18} />}
              onClick={handlePaymentMethodsClick}
            />

            <Divider />
            <MenuButton
              label={t("menuItems.signOut")}
              startIcon={() => <LogOutIcon size={18} />}
              onClick={handleSignOutClick}
            />

            <Divider />
          </div>
        </div>
      )}

      {!isSmallScreen && (
        <div className="flex w-full items-center justify-end gap-3 border-b border-neutral-surface-highlight bg-neutral-background px-6 py-2">
          <QuestionCircleIcon
            width={24}
            height={24}
            onClick={(event) => {
              // Prevents the popover menu from opening
              event.stopPropagation();
              handleHelpIconClick();
            }}
            cursor="pointer"
          />

          <div className="flex flex-row items-center gap-2 p-2">
            <UserNameInitials firstName={firstName} lastName={lastName} />

            <Popover
              visible={menuOpen}
              onVisibility={(visible) => handleVisibilityChange(visible)}
              arrow={false}
              padding="small"
              width="250px"
              position="bottom-end"
              content={
                <div className="flex w-full flex-col gap-2">
                  <MenuButton
                    label={t("menuItems.paymentsAndSubscriptions")}
                    onClick={handleMyAccountClick}
                  />
                  <MenuButton
                    label={t("menuItems.plans")}
                    onClick={handlePlansClick}
                  />

                  {hasMulticd && (
                    <MenuButton
                      label={t("menuItems.changeLocation")}
                      onClick={handleChangeLocationClick}
                    />
                  )}

                  <MenuButton
                    label={t("menuItems.signOut")}
                    startIcon={() => <LogOutIcon size={18} />}
                    onClick={handleSignOutClick}
                  />
                </div>
              }
            >
              <div className="flex cursor-pointer flex-row items-center gap-2 text-primary-text-low ">
                <div className="flex flex-col items-start justify-center gap-1">
                  <div className="text-sm font-medium">
                    {selectedLocationOrStoreName}
                  </div>
                </div>
                {menuOpen ? (
                  <ChevronUpIcon width={20} height={20} />
                ) : (
                  <ChevronDownIcon width={20} height={20} />
                )}
              </div>
            </Popover>
          </div>
        </div>
      )}

      <ConfirmationModal
        open={signOutConfirmationModalOpen}
        onDismiss={() => setSignOutConfirmationModalOpen(false)}
        onConfirm={handleSignOutConfirmation}
        title={t("signOutConfirmationModal.title")}
        description={t("signOutConfirmationModal.description")}
        dismissButtonText={t("signOutConfirmationModal.dismissButtonText")}
        confirmButtonText={t("signOutConfirmationModal.confirmButtonText")}
      />

      <ShortcutListModalWidget
        open={shortcutListOpen}
        onAfterModalLeave={() => setShortcutListOpen(false)}
      />

      {selectLocationModalOpen && (
        <SelectLocationModalWidget
          onAfterModalLeave={() => setSelectLocationModalOpen(false)}
          initialOriginLocation={selectedOriginLocation}
          initialFulfillmentLocation={selectedFulfillmentLocation}
        />
      )}
    </>
  );
}
