import { useMemo } from "react";
import { find } from "lodash";
import useLocalStorageState from "use-local-storage-state";

import {
  SELECTED_FULFILLMENT_LOCATION_ID_LOCAL_STORAGE_KEY,
  SELECTED_ORIGIN_LOCATION_ID_LOCAL_STORAGE_KEY,
} from "~/constants";
import { appTRPC } from "~/providers/TRPCProvider";
import { type Location } from "~/types";

const useSelectedLocation = () => {
  const [originLocationId, setOriginLocationId] = useLocalStorageState<string>(
    SELECTED_ORIGIN_LOCATION_ID_LOCAL_STORAGE_KEY,
  );
  const [fulfillmentLocationId, setFulfillmentLocationId] =
    useLocalStorageState<string>(
      SELECTED_FULFILLMENT_LOCATION_ID_LOCAL_STORAGE_KEY,
    );

  const query = appTRPC.store.getLocations.useQuery(undefined, {
    gcTime: Infinity,
    staleTime: 60 * 1000,
  });

  const { data: locations } = query;

  const defaultLocation = find(locations, { isDefault: true });

  const selectedOriginLocation = find<Location>(locations, {
    id: originLocationId,
  });

  const selectedFulfillmentLocation = find<Location>(locations, {
    id: fulfillmentLocationId,
  });

  const selectedOriginLocationOrDefault = useMemo(() => {
    return selectedOriginLocation ?? defaultLocation;
  }, [defaultLocation, selectedOriginLocation]);

  const selectedFulfillmentLocationOrDefault = useMemo(() => {
    return selectedFulfillmentLocation ?? defaultLocation;
  }, [defaultLocation, selectedFulfillmentLocation]);

  const saveLocations = ({
    originLocationId,
    fulfillmentLocationId,
  }: {
    originLocationId?: string;
    fulfillmentLocationId?: string;
  }) => {
    if (originLocationId) {
      setOriginLocationId(originLocationId);
    }

    if (fulfillmentLocationId) {
      setFulfillmentLocationId(fulfillmentLocationId);
    }
  };

  return {
    query,
    isLocationSelected: !!originLocationId,
    selectedOriginLocation,
    selectedOriginLocationOrDefault,
    selectedFulfillmentLocation,
    selectedFulfillmentLocationOrDefault,
    saveLocations,
  };
};

export default useSelectedLocation;
