"use client";

import logger from "@offline/logger/logger";

import getLocationStock from "~/helpers/products/getLocationStock";
import { useCheckoutStore, useIsCheckoutValid } from "~/hooks";
import useSelectedLocation from "~/hooks/useSelectedLocation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import { appTRPC } from "~/providers/TRPCProvider";
import InsufficientStockInCartModal from "./InsufficientStockInCartModal";

type Props = {
  open: boolean;
  onDismiss: () => void;
  onStockUpdate: () => void;
};

function InsufficientStockInCartModalWidget({
  open,
  onDismiss,
  onStockUpdate,
}: Readonly<Props>) {
  const trpcUtils = appTRPC.useUtils();
  const { setCartItemsStock } = useCheckoutStore();
  const { hasMulticd } = useStoreInfo() || {};

  const {
    mutateAsync: increaseStock,
    isPending: isIncreasingStock,
    error: increaseStockError,
  } = appTRPC.products.increaseStock.useMutation();

  const { selectedFulfillmentLocation } = useSelectedLocation();
  const { insufficientStockItems } = useIsCheckoutValid();

  const shouldIncreaseInLocation =
    hasMulticd && selectedFulfillmentLocation?.id;

  const handleConfirm = async () => {
    const items = insufficientStockItems.map(
      ({
        product: { id: productId },
        variant: { id: variantId },
        variant,
        quantity,
      }) => {
        const stock = getLocationStock(
          variant,
          selectedFulfillmentLocation?.id,
        );

        return {
          productId,
          variantId,
          value: quantity - stock,
          locationId: shouldIncreaseInLocation
            ? selectedFulfillmentLocation.id
            : undefined,
        };
      },
    );

    try {
      const updatedItems = await increaseStock(items);
      setCartItemsStock(updatedItems);
      await trpcUtils.products.getProducts.invalidate();
      onStockUpdate();
    } catch (err) {
      logger.error("Failed to update stock", err);
    }
  };

  return (
    <InsufficientStockInCartModal
      open={open}
      items={insufficientStockItems}
      onDismiss={onDismiss}
      onConfirm={handleConfirm}
      error={!!increaseStockError}
      loading={isIncreasingStock}
    />
  );
}

export default InsufficientStockInCartModalWidget;
