"use client";

import { useState, type ChangeEventHandler } from "react";
import { map, orderBy } from "lodash";
import { useTranslations } from "next-intl";

import FullscreenModal from "~/components/FullscreenModal";
import FullscreenModalFooter from "~/components/FullscreenModal/FullscreenModalFooter";
import { Button, Checkbox, Select, SelectField, useToast } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { getLocaleFieldValue } from "~/helpers/i18n";
import { useCheckoutStore } from "~/hooks";
import useSelectedLocation from "~/hooks/useSelectedLocation";
import { usePathname, useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import { type Location } from "~/types";
import ErrorMessage from "./ErrorMessage";

type Props = {
  onAfterModalLeave?: () => void;
  initialOriginLocation?: Location;
  initialFulfillmentLocation?: Location;
};

function SelectLocationModalWidget({
  onAfterModalLeave,
  initialFulfillmentLocation,
  initialOriginLocation,
}: Readonly<Props>) {
  const pathname = usePathname();
  const router = useRouter();
  const t = useTranslations("widgets.select-location-modal");
  const storeInfo = useStoreInfo();
  const { adminLanguage, country } = storeInfo || {};
  const { addToast } = useToast();

  const { query, saveLocations } = useSelectedLocation();

  const { data: locations, error, refetch } = query;
  const isLoading = query.isLoading || query.isFetching;

  const { reset: resetCheckout } = useCheckoutStore();

  const [open, setOpen] = useState(true);

  const isInitialSameLocationId =
    initialOriginLocation &&
    initialFulfillmentLocation?.id &&
    initialFulfillmentLocation?.id === initialOriginLocation?.id;

  const [selectedOriginLocationId, setSelectedOriginLocationId] = useState<
    string | undefined
  >(initialOriginLocation?.id);
  const [selectedFulfillmentLocationId, setSelectedFulfillmentLocationId] =
    useState<string | undefined>(initialFulfillmentLocation?.id);
  const [hasOtherFulfillmentLocation, setHasOtherFulfillmentLocation] =
    useState(initialOriginLocation ? !isInitialSameLocationId : false);

  const isEditMode = !!initialOriginLocation;
  const locale = `${adminLanguage}_${country?.toUpperCase()}`;

  const handleOriginLocationChange: ChangeEventHandler<HTMLSelectElement> = (
    e,
  ) => {
    const locationId = e.target.value;
    if (locationId === selectedFulfillmentLocationId) {
      setSelectedFulfillmentLocationId(undefined);
    }

    setSelectedOriginLocationId(locationId);
  };

  const handleFulfillmentLocationChange: ChangeEventHandler<
    HTMLSelectElement
  > = (e) => {
    const locationId = e.target.value;

    setSelectedFulfillmentLocationId(locationId);
  };

  const handleSaveButtonClick = () => {
    saveLocations({
      originLocationId: selectedOriginLocationId,
      fulfillmentLocationId: hasOtherFulfillmentLocation
        ? selectedFulfillmentLocationId
        : selectedOriginLocationId,
    });

    addToast({
      id: "select-location-toast",
      text: t(`toast.${isEditMode ? "edit" : "add"}`),
      type: "success",
      duration: 4000,
    });

    resetCheckout();
    setOpen(false);

    const originLocationChanged =
      selectedOriginLocationId !== initialOriginLocation?.id;
    const fulfillmentLocationChanged =
      selectedFulfillmentLocationId !== initialFulfillmentLocation?.id;

    if (originLocationChanged || fulfillmentLocationChanged) {
      let changeType =
        originLocationChanged && fulfillmentLocationChanged
          ? "both"
          : undefined;

      if (!changeType) {
        changeType = originLocationChanged ? "origin" : "inventory";
      }

      if (pathname.includes("/registers/")) {
        // Redirects the user to the root segment when the current URL is scoped
        // by an ID that might depend on a location.
        // This prevents displaying data from a location that is not the selected one.
        // e.g. /registers/{id}/active -> /registers
        router.replace(`/registers`);
      }

      trackAmplitudeEvent("pdv_location_change", {
        location_change_type: changeType,
        use_origin_as_inventory: hasOtherFulfillmentLocation,
      });
    }
  };

  return (
    <FullscreenModal
      open={open}
      title={t("title")}
      description={t("description")}
      closable={isEditMode}
      onAfterModalLeave={onAfterModalLeave}
    >
      <div className="flex w-full flex-col sm:w-[600px]">
        {error && !isLoading && <ErrorMessage onRetryClick={refetch} />}
        {(!error || isLoading) && (
          <div className="flex flex-col gap-4">
            <SelectField
              id="sales-location"
              name="sales-location"
              label={t("originLocationSelect.label")}
              value={selectedOriginLocationId ?? "empty"}
              loading={isLoading}
              onChange={handleOriginLocationChange}
            >
              <Select.Option
                disabled
                label={t("originLocationSelect.emptyItemLabel")}
                value="empty"
              />
              {map(orderBy(locations, ["createdAt"]), (location) => {
                const name = getLocaleFieldValue(location.name, locale);

                return (
                  <Select.Option
                    key={location.id}
                    label={name}
                    value={location.id}
                  />
                );
              })}
            </SelectField>

            <div className="mt-2">
              <Checkbox
                name="has-other-inventory-location"
                label={t("otherFulfillmentLocationCheckbox")}
                defaultChecked={hasOtherFulfillmentLocation}
                disabled={!selectedOriginLocationId || isLoading}
                onChange={({ target: { checked } }) => {
                  setHasOtherFulfillmentLocation(checked);
                  setSelectedFulfillmentLocationId(undefined);
                }}
              />
            </div>

            {hasOtherFulfillmentLocation && (
              <SelectField
                id="inventory-location"
                name="inventory-location"
                label={t("fulfillmentLocationSelect.label")}
                value={selectedFulfillmentLocationId ?? "empty"}
                loading={isLoading}
                onChange={handleFulfillmentLocationChange}
              >
                <Select.Option
                  disabled
                  label={t("originLocationSelect.emptyItemLabel")}
                  value="empty"
                />
                {map(orderBy(locations, ["createdAt"]), (location) => {
                  if (selectedOriginLocationId === location.id) {
                    return null;
                  }

                  const name = getLocaleFieldValue(location.name, locale);

                  return (
                    <Select.Option
                      key={location.id}
                      label={name}
                      value={location.id}
                    />
                  );
                })}
              </SelectField>
            )}
          </div>
        )}
      </div>

      <FullscreenModalFooter>
        {isEditMode && (
          <Button
            className="flex-1"
            variant="neutral"
            onClick={() => setOpen(false)}
          >
            {t("buttons.cancel")}
          </Button>
        )}
        <Button
          className="flex-1"
          disabled={
            !selectedOriginLocationId ||
            (hasOtherFulfillmentLocation && !selectedFulfillmentLocationId)
          }
          onClick={handleSaveButtonClick}
        >
          {t("buttons.save")}
        </Button>
      </FullscreenModalFooter>
    </FullscreenModal>
  );
}

export default SelectLocationModalWidget;
