import { type PropsWithChildren } from "react";

export default function FullscreenModalFooter({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex justify-center border-t border-t-neutral-surface-disabled bg-white py-6">
      <div className="w-fix mx-2 flex w-full gap-2 sm:w-[600px]">
        {children}
      </div>
    </div>
  );
}
