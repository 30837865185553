import { Dialog } from "@headlessui/react";

import { Button } from "~/components";

type Props = {
  open: boolean;
  title: string;
  description: string;
  confirmButtonText: string;
  dismissButtonText: string;
  onDismiss: () => void;
  onConfirm: () => void;
  testId?: string;
};

export default function ConfirmModal({
  open,
  title,
  description,
  confirmButtonText,
  dismissButtonText,
  onDismiss,
  onConfirm,
  testId,
}: Readonly<Props>) {
  return (
    <Dialog open={open} onClose={onDismiss}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div
        className="fixed inset-0 mx-auto flex max-w-2xl items-center justify-center"
        data-testid={testId}
      >
        <Dialog.Panel className="mx-4 min-w-fit rounded-lg bg-white p-6">
          <Dialog.Title className="text-xl font-medium leading-6">
            {title}
          </Dialog.Title>

          <Dialog.Description className="py-3 text-base text-neutral-text-disabled sm:py-4 sm:text-lg">
            {description}
          </Dialog.Description>

          <div className="flex flex-col-reverse gap-2 sm:flex-row sm:gap-4">
            <Button variant="neutral" onClick={onDismiss} className="w-full">
              {dismissButtonText}
            </Button>

            <Button variant="primary" className="w-full" onClick={onConfirm}>
              {confirmButtonText}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
