import { type Logger as WinstonLogger } from "winston";

const IS_DEV = process.env.NODE_ENV !== "production";

/**
 * NOSONAR TODO: this type is incorrect for `edge` runtime
 * We should either:
 *  - Have separate loggers (one for `nodejs`, another for `edge`)
 *  - Use `Logger | Console` as type and fix resulting errors (e.g. `.child()` method does not exist in Console)
 */
type Logger = Pick<
  WinstonLogger,
  "info" | "debug" | "warn" | "error" | "child"
>;

const logger: Logger = (() => {
  return process.env.NEXT_RUNTIME === "nodejs"
    ? (() => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const winston = require("winston");
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { consoleFormat } = require("winston-console-format");

        const { createLogger, format, transports } = winston;

        return createLogger({
          level: IS_DEV ? "debug" : "info",
          format: format.json(),
          transports: [
            IS_DEV
              ? new transports.Console({
                  format: format.combine(
                    // eslint-disable-next-line @typescript-eslint/no-var-requires
                    require("./formatApiClientError").default(),
                    format.colorize({ all: true }),
                    consoleFormat({
                      showMeta: true,
                      inspectOptions: {
                        depth: Infinity,
                        colors: true,
                        maxArrayLength: Infinity,
                        breakLength: 120,
                        compact: Infinity,
                      },
                    }),
                  ),
                })
              : new transports.Console(),
          ],
        });
      })()
    : console;
})();

export { type Logger };

export default logger;
