"use client";

import { Dialog } from "@headlessui/react";
import { useTranslations } from "next-intl";

import { Button, useToast } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import isMissingFullAccessRoleError from "~/helpers/isMissingFullAccessRoleError";
import { useRouter } from "~/navigation";
import { appTRPC } from "~/providers/TRPCProvider";

export default function EnsureSubscriptionWidget() {
  const t = useTranslations("widgets.ensure_subscription");
  const trpcUtils = appTRPC.useUtils();
  const { addToast } = useToast();
  const router = useRouter();

  const { data: subscriptionSummary } =
    appTRPC.planSubscriptions.getSummary.useQuery();

  const {
    mutateAsync: selectFreeTrialPlan,
    isPending: selectFreeTrialPlanLoading,
  } = appTRPC.planSubscriptions.selectFreeTrialPlan.useMutation();

  const open = subscriptionSummary?.status === "NOT_STARTED";

  const handleSelectPlan = async () => {
    try {
      const { plan } = await selectFreeTrialPlan();
      await trpcUtils.planSubscriptions.getSummary.invalidate();
      await trpcUtils.store.getStoreInfo.invalidate();

      trackAmplitudeEvent("pdv_plans_select", {
        current_plan: subscriptionSummary?.subscription?.plan?.category,
        target_plan: plan.category,
        plan_change_type: "upgrade",
      });
    } catch (error) {
      if (isMissingFullAccessRoleError(error)) {
        router.push("/errors/subscription-forbidden");
        return;
      }

      addToast({
        id: "error-toast",
        text: t("errorToast"),
        type: "danger",
        duration: 8000,
      });
    }
  };

  return (
    <Dialog open={open} onClose={() => {}}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="max-w-2xl rounded-lg bg-white p-6 sm:w-[600px]">
          <div className="flex justify-between">
            <Dialog.Title className="text-xl font-medium leading-6">
              {t("title")}
            </Dialog.Title>
          </div>

          <div className="relative flex flex-1 flex-col gap-4 py-3">
            <p className="text-lg font-normal not-italic leading-6 text-neutral-text-disabled">
              {t("body")}
            </p>
          </div>
          <div className="mt-6 flex justify-center gap-4">
            <Button
              variant="primary"
              onClick={handleSelectPlan}
              loading={selectFreeTrialPlanLoading}
              disabled={selectFreeTrialPlanLoading}
            >
              {t("submitButton")}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
